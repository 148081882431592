<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h1>Datos del cliente</h1>
      <div style="text-align: start">
        <input
              type="text"
              list="clientes"
              id="cliente"
              @input="handleInput"
              v-model="selectedOption"
              class="form-control"
            />

            <datalist id="clientes">
              <option
                v-for="option in options"
                :key="option.id"
                :value="option.value"
              ></option>
            </datalist>
        <hr />
        <span>Pedido</span>
        <div
          v-for="(articulo, index) in pedido.articulos"
          :key="index"
          class="p-2 my-5 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
            {{ articulo.articulo }} {{ articulo.color }}
            <div  class="mx-2 d-flex flex-row flex-wrap suelas" :data-suela="articulo.articulo" :data-color="articulo.color">
              <div class="suelas-talle" :data-talle="talle.talle" :data-cantidad="talle.cantidad" v-for="(talle, index) in articulo.talles" :key="index">
                <div class="p-2">
                  <b>{{ talle.talle }}</b> - {{ talle.cantidad }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 d-flex flex-column justify-content-">
            <button
              class="btn"
              @click="
                borrar(articulo.articulo, articulo.color, articulo.talles)
              "
            >
              <img src="../../public/img/icons/delete-boton.svg" />
            </button>
            <button
              class="btn"
              @click="edit(articulo.articulo, articulo.color, articulo.talles)"
            >
              <img src="../../public/img/icons/edit-boton.svg" />
            </button>
          </div>
        </div>
        <hr />
        <div
          v-for="(articulo, index) in pedidoTela.articulos"
          :key="index"
          class="p-2 my-5 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
            {{ articulo.articulo }}
            <div class="mx-2 d-flex flex-row flex-wrap telas" :data-tela="articulo.articulo">
              <div v-for="(color, index) in articulo.colores" :key="index" :data-telasColor="color.color" :data-telasCantidad="color.cantidad">
                <div v-if="color.cantidad != 0" class="p-2 telas-color" >
                  <b>{{ color.color }}</b> - {{ color.cantidad }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 d-flex flex-column justify-content-">
            <button
              class="btn"
              @click="borrarTela(articulo.articulo, articulo.colores)"
            >
              <img src="../../public/img/icons/delete-boton.svg" />
            </button>
            <button
              class="btn"
              @click="editTela(articulo.articulo, articulo.colores)"
            >
              <img src="../../public/img/icons/edit-boton.svg" />
            </button>
          </div>
        </div>
      </div>
      <hr class="w-100" />
      <button type="button" class="btn btn-primary" @click="enviarFormulario">
        Confirmar pedido
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import axios from 'axios';
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
export default {

  setup() {
    const router = useRouter()

    if(localStorage.getItem('token')){
      console.log('log');
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }

    const clientes = reactive([]);
    const selectedOption = ref("");
    const options = reactive([]);
    //GET CLIENTE
    axios
      .get(" https://d7.osole.com.ar/balkun/allclientes")
      .then((response) => {        
        response.data.forEach(function (item) {          
          const objArt = {
            id: item.id,
            value: item.empresa,
            label: item.empresa,            
          };
          options.push(objArt);
        });        
      })
      .catch((error) => {
        console.error(error);
      });

    //ESCUCHAR INPUT CLIENTE
    const handleInput = (event) => {
      const selectedOption = options.find(
        (options) => options.value === event.target.value
        );
    };

    //FORMULARIO
    const enviarFormulario = () =>{
      const data = new FormData();
      const pedidoSuelas = [];
      const pedidoTelas = [];
      const suelas = document.querySelectorAll('.suelas')      
      const telas = document.querySelectorAll('.telas')

      suelas.forEach(suela => {
        let suelaTalles = [];
        let talles = suela.children;        
        
        for (let index = 0; index < talles.length; index++) {
          let talle = talles[index];
          let tallesArticulo = {};
          
          tallesArticulo.talle = talle.dataset.talle;
          tallesArticulo.cantidad = talle.dataset.cantidad;
          suelaTalles.push(tallesArticulo);
        }        
        let articulo = {name:suela.dataset.suela,color:suela.dataset.color,talles:suelaTalles}
        pedidoSuelas.push(articulo)
      });
      
      
      telas.forEach(tela => {
        let telaColores = [];
        let colores = tela.children;        
        
        for (let index = 0; index < colores.length; index++) {
          let color = colores[index];
          let coloresArticulo = {};
          if(color.dataset.telascantidad > 0){
            coloresArticulo.color = color.dataset.telascolor;
            coloresArticulo.cantidad = color.dataset.telascantidad;          
            telaColores.push(coloresArticulo);
          }
        }        
        let articulo = {name:tela.dataset.tela,colores:telaColores}
        pedidoTelas.push(articulo)
      });
      data.append('suelas',JSON.stringify(pedidoSuelas))
      data.append('telas',JSON.stringify(pedidoTelas))
      data.append('cliente',selectedOption.value)
      console.log(selectedOption.value)
      axios.post('https://d7.osole.com.ar/balkun/formCarrito', data)
        .then(response => {
          //Manejar la respuesta de la API aquí
          localStorage.removeItem("pedidoTela");
          localStorage.removeItem("editTela");
          localStorage.removeItem("arrPedido");
          localStorage.removeItem("arrPedidoTela");
          localStorage.removeItem("pedido");
          console.log(response.data);
          window.location.href = window.location.protocol + "//" + window.location.host + "/home/";
        })
        .catch(error => {
          // Manejar el error aquí
          console.log(error);
        });
    }

    const pedido = reactive({
      articulos: [],
    });

    const pedidoTela = reactive({
      articulos: [],
    });

    const StoragePedido = JSON.parse(localStorage.getItem("arrPedido"));

    if(StoragePedido){
      StoragePedido.forEach((element) => {
        pedido.articulos.push(element);
      });
    }

    const StoragePedidoTela = JSON.parse(localStorage.getItem("arrPedidoTela"));

    if(StoragePedidoTela){

      StoragePedidoTela.forEach((element) => {
        pedidoTela.articulos.push(element);
      });
    }

    const borrar = (articulo,color) => {
      if(pedido.articulos.find((art) => art.articulo === articulo)  && pedido.articulos.find((art) => art.color === color)){
        let art = pedido.articulos.find((art) => art.articulo === articulo)
        let index = pedido.articulos.indexOf(art);
        pedido.articulos.splice(index,1);
        StoragePedido.splice(index,1)
        localStorage.setItem("arrPedido", JSON.stringify(StoragePedido));
      }
    };

    const borrarTela = (articulo) => {
      if(pedidoTela.articulos.find((art) => art.articulo === articulo)){

        let art = pedidoTela.articulos.find((art) => art.articulo === articulo)
        let index = pedidoTela.articulos.indexOf(art);
        pedidoTela.articulos.splice(index,1);
        StoragePedido.splice(index,1)
        localStorage.setItem("arrPedido", JSON.stringify(StoragePedido));
      }
    };
    const edit = (articulo,color,talles) => {
      let tallesAux = "";
      talles.forEach( e => {
        tallesAux += e.talle+",";
      })

      tallesAux = tallesAux.substring(0, tallesAux.length - 1);
      talles = tallesAux;
      const objEdit = {
          articulo: articulo,
          talles: talles,
          color: color,
        };
      localStorage.setItem("edit", JSON.stringify(objEdit));
      window.location.href = window.location.protocol + "//" + window.location.host + "/pedidos/";
    }

    const editTela = (articulo,colores) => {
      let coloresAux = "";
      colores.forEach( e => {
        coloresAux += e.color+",";
      })

      coloresAux = coloresAux.substring(0, coloresAux.length - 1);
      colores = coloresAux;
      const objEdit = {
          articulo: articulo,
          colores: colores,
        };
      localStorage.setItem("editTela", JSON.stringify(objEdit));
      window.location.href = window.location.protocol + "//" + window.location.host + "/pedidos_telas/";
    }

    return {
      pedido,
      pedidoTela,
      edit,
      borrar,
      editTela,
      borrarTela,
      enviarFormulario,
      clientes,
      handleInput,
      selectedOption,
      options,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
