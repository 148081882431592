import { createStore } from 'vuex';

const store = createStore({
    state: {
        token: ''
    },
    mutations: {
        setToken(state, token) {
            console.log('token:', token)
            state.token = token
        }
    },
    actions: {
        setToken({ commit }, token) {
            console.log('token:', token)
            commit('SET_TOKEN', token)
        },
        logout({ commit }) {
            commit('setToken', null)
            // Elimina cualquier otra información de sesión aquí
        }
    }
})

export default store;
