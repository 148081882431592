
<template>
  <div class="d-flex justify-content-center align-items-center flex-column py-3" style="background: #fff;">
      <div class="p-3 box my-3">
        <router-link to="/pedidos/suelas"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/pedidos.svg" class="mb-4">
          <b>PEDIDOS</b>
      </router-link>
      </div>

      <div class="p-3 box my-3">
        <router-link to="/pendientes"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/pedidos.svg" class="mb-4">
          <b>PENDIENTE DE ENTREGA</b>
      </router-link>
      </div>

      <div class="p-3 box my-3">
        <router-link to="/historial"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/historial.svg" class="mb-4">
          <b>HISTORIAL</b>
      </router-link>
      </div>

      <div class="p-3 box my-3">
        <router-link to="/"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/lista.svg" class="mb-4">
          <b>LISTA DE PRECIOS</b>
      </router-link>
      </div>

      <div class="p-3 box my-3">
        <router-link to="/carrito"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/carrito.svg" class="mb-4">
          <b>CARRITO</b>
        </router-link>
      </div>

      <div class="p-3 box my-3">
        <router-link to="/clientes"
        class="card d-flex flex-column justify-content-centert align-items-center p-5"
        style="background: #F5F5F5;border-radius: 0px;text-decoration: none;" >
        <img src="../../public/img/icons/carrito.svg" class="mb-4">
          <b>CLIENTES</b>
        </router-link>
      </div>
  </div>
</template>

<style>
.box{
  width: 328px;
  height: auto!important;  
  border: 1px solid #ddd;
  background: #f5f5f5;
}
</style>
<script>
import { useRouter } from 'vue-router'
import { onMounted } from "vue";
export default({  
  setup() {
    const router = useRouter()
    onMounted(() => {
      const navBar = document.getElementById('navBar');
      if(localStorage.getItem('token')){        
        navBar.classList.remove("show");      
      }else{
        router.push('/')
      }            
      navBar.querySelector('ul').classList.remove('d-none');
      });
  },
})
</script>

