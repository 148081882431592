import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { NavigationGuard } from 'vue-router';
import jwtDecode from 'jsonwebtoken';
import store from '@/store';

import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import PedidoView from '../views/PedidoView.vue'
import PedidoTelasView from '../views/PedidoTelasView.vue'
import SuelasView from '../views/SuelasView.vue'
import TelasView from '../views/TelasView.vue'
import CarritoView from '../views/CarritoView.vue'
import HistorialView from '../views/HistorialView.vue'
import NewClienteView from '../views/NewClienteView.vue'
import PendientesView from '../views/PendientesView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: PedidoView
  },
  {
    path: '/pedidos_telas',
    name: 'pedidos_telas',
    component: PedidoTelasView
  },
  {
    path: '/pedidos/suelas',
    name: 'pedidos suelas',
    component: SuelasView
  },
  {
    path: '/pedidos/telas',
    name: 'pedidos telas',
    component: TelasView
  },
  {
    path: '/carrito',
    name: 'carrito',
    component: CarritoView
  },
  {
    path: '/historial',
    name: 'historial',
    component: HistorialView
  },
  {
    path: '/pendientes',
    name: 'pendientes',
    component: PendientesView
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: NewClienteView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresAuth && !store.state.token) {    
//     next('/')
//   } else {    
//     next()
//   }
// })


export default router
