<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h3>PEDIDOS SUELAS</h3>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <router-link
            to="/pedidos/suelas"
            class="nav-link active"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Suelas
          </router-link>
        </li>
        <li class="nav-item" role="presentation">
          <router-link
            to="/pedidos/telas"
            class="nav-link active"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Telas
          </router-link>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          <div class="py-5">
            <!-- ARTICULO -->
            <div class="my-2" style="text-align: start">
              <b>Eleg&iacute; la suela</b>
            </div>
            <input
              type="text"
              list="suelas"
              id="suela"
              @input="handleInput"
              v-model="selectedOption"
              data-talle=""
              class="form-control"
            />
            <datalist id="suelas">
              <option
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                :data-color="option.data"
              ></option>
            </datalist>

            <!-- COLORES -->
            <div v-if="selectedOption">
              <div class="my-2" style="text-align: start">
                <b>Color</b>
              </div>
              <input type="text" list="colores" id="color" onchange="" class="form-control" />
              <datalist id="colores">
                <option
                  v-for="(color, index) in colores"
                  :key="index"
                  :value="color"
                ></option>
              </datalist>
            </div>
          </div>
        </div>        
      </div>
      <button
        v-if="selectedOption"
        type="button"
        class="btn btn-primary"
        @click="pedido"
      >
        AGREGAR
      </button>
    </div>
  </div>
</template>
<script></script>
<script>
import { reactive, ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){
      console.log('log');
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");    
    }else{
      router.push('/')
    }
    const selectedOption = ref("");
    let colores = [];
    let talles = [];
    const store = useStore();
    const options = reactive([]);
    axios
      .get(" https://d7.osole.com.ar/balkun/allsuelas")
      .then((response) => {
        const  token  = 'bBaAlLkKuUmMpPwWaA';
          store.commit('setToken', token)
        response.data.forEach(function (item) {
          const objArt = {
            id: item.id,
            value: item.code + " " + item.name,
            label: item.code + " " + item.name,
            color: ["Marrón", "Beish", "Blanco", "Negro"],
            talle: ["35", "36", "37"],
          };
          options.push(objArt);
        });
      })
      .catch((error) => {
        console.error(error);
      });

    const handleInput = (event) => {
      const selectedOption = options.find(
        (options) => options.value === event.target.value
      );
      if (selectedOption) {
        let suelas = document.getElementById("suela");
        suelas.dataset.talle = selectedOption.talle;
        let listColores = document.getElementById("colores");
        let arrColores = selectedOption.color;
        talles = selectedOption.talle;
        listColores.innerHTML = "";
        arrColores.forEach(function (item) {
          var option = document.createElement("option");
          option.value = item;
          listColores.appendChild(option);
        });
      }
    };

    const pedido = () => {
      let suelas = document.getElementById("suela");
      let color = document.getElementById("color");
      let pedido;
      const objPedido = {
        articulo: suelas.value,
        talles: suelas.dataset.talle,
        color: color.value,
      };
      // pedido = localStorage.getItem("pedido");
      // if (pedido != null) {
      //   pedido = JSON.parse(pedido);
      //   if (
      //     pedido.find((articulo) => articulo.articulo === objPedido.articulo) ==
      //     undefined
      //   ) {
      //     pedido.push(objPedido);
      //     localStorage.setItem("pedido", JSON.stringify(pedido));
      //   }
      // } else {
      //let arrObj = [];
      //arrObj.push(objPedido);
      localStorage.setItem("pedido", JSON.stringify(objPedido));
      //}
      router.push('/pedidos')
      //window.location.href = window.location.protocol + "//" + window.location.host + "/pedidos/";
    };

    return {
      selectedOption,
      handleInput,
      options,
      colores,
      talles,
      pedido,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
