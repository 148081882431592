import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import SuelasView from './views/SuelasView.vue'
import TelasView from './views/TelasView.vue'
import TopBar from './components/TopBar.vue'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import store from './store';
/* add icons to the library */
library.add(faUserSecret)

const app = createApp(App).use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.component('SuelasView', SuelasView)
.component('TelasView', TelasView)
.component('TopBar', TopBar)
app.use(store);
app.use(router)
app.mount('#app');