<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h1>PEDIDOS</h1>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link to="/pedidos/suelas" class="nav-link active">
            Suelas
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pedidos/telas" class="nav-link active">
            Telas
          </router-link>
        </li>
      </ul>

      <hr />

      <div style="text-align: start">
        <div class="d-flex flex-row justify-content-between">
          <b>{{ obj.nombre }}</b>
          <button class="btn">
            <svg
              style="width: 14px; height: 18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
              />
            </svg>
          </button>
        </div>
        <div class="d-flex flex-row flex-wrap">
          <div v-for="(color, index) in obj.colores" :key="index" class="mx-2">
            {{ color }}
            <input
              @input="onInputChanged"
              type="number"
              value="0"
              :data-color="color"
              class="form-control inputColores"
              id="colors"
              style="width: 76px; height: 40px"
            />
          </div>
        </div>
        <div class="py-3">
          <span>Subtotal: </span><span id="subtotal">{{ state.subtotal }}</span>
        </div>
      </div>
      <hr class="w-100" />
      <button type="button" class="btn btn-primary" @click="continuarPedido">
        Continuar al carrito
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import { onMounted } from "vue";
export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){
      console.log('log');
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }    

    const StoragePedido = ref("");
    const edit = ref("");
    const state = reactive({
      subtotal: "0",
      colorCantidad: [],
    });
    const obj = reactive({
      nombre: "",      
      colores: "",
    });
    onMounted(() => {
      const StoragePedido = JSON.parse(localStorage.getItem("editTela"));
      let accion = 'pedidoTela';
      if(StoragePedido){
        accion = 'editTela';
      }
      cargarObj(obj,accion)     
    });    

    const cargarObj = (obj,accion) => {      
      const StoragePedido = JSON.parse(localStorage.getItem(accion));      
        obj.nombre = StoragePedido.articulo;
        if(accion == 'editTela'){
          obj.colores = StoragePedido.colores.split(",");
        }else{
          obj.colores = JSON.parse(StoragePedido.colores);
        }
    };

    const onInputChanged = () => {
      let inputColores = document.querySelectorAll(".inputColores");
      let total = 0;
      inputColores.forEach((element) => {
        let color = element.dataset.color;
        let articulo = ({'color': color,'cantidad': element.value});
        
        if(element.value){
          if(state.colorCantidad.find((art) => art.color === articulo.color) == undefined){
            state.colorCantidad.push(articulo)
          }else{            
            state.colorCantidad.find((art) => art.color === articulo.color)['cantidad'] = element.value            
          }          
          total += parseInt(element.value);
        }

      });
      state.subtotal = total;
    };

    const continuarPedido = () => {
        const objPedido = {
          articulo: obj.nombre,
          colores: state.colorCantidad,
        };        
        let arrPedido = localStorage.getItem("arrPedidoTela");
        if (arrPedido != null ) {
          arrPedido = JSON.parse(arrPedido);            
          if(arrPedido.find(articulo => articulo.articulo === objPedido.articulo) == undefined){
            arrPedido.push(objPedido);            
          }else{
            let edit = arrPedido.find(articulo => articulo.articulo === objPedido.articulo)
            edit.colores = state.colorCantidad;            
            localStorage.removeItem("editTela")
          }
          localStorage.setItem("arrPedidoTela", JSON.stringify(arrPedido));
        } else {          
          let arrPedido = []   
          arrPedido.push(objPedido);       
          localStorage.setItem("arrPedidoTela", JSON.stringify(arrPedido));
        }
        window.location.href = window.location.protocol + "//" + window.location.host + "/carrito/";
    };
    return {
      StoragePedido,
      obj,
      state,
      edit,
      onInputChanged,
      continuarPedido,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
