<template>
      <ul  v-if="localStorage && localStorage.token" class="navbar-nav">
        <li class="nav-item"><router-link to="/home">Inicio</router-link></li>
        <li class="nav-item"><router-link to="/pedidos/suelas">Pedidos</router-link></li>
        <li class="nav-item"><router-link to="/pendientes">Pendientes</router-link></li>
        <li class="nav-item"><router-link to="/historial">Historial</router-link></li>
        <li class="nav-item"><router-link to="/carrito">Carrito</router-link></li>
        <li class="nav-item"><router-link to="/Clientes">clientes</router-link></li>
        <li class="nav-item"> <button class="btn" @click="logout">Cerrar session</button></li>
      </ul>
</template>
<script>
export default {
  name: 'TopBar',
  methods: {
    myMethod() {
      // Lógica que usa localStorage
    }
  }
}
</script>