<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h1>Nuevo cliente</h1>
      <h1>Datos del cliente</h1>
      <div style="text-align: start">
        <label>Nombre y apellido</label>
        <input type="text" id="cliente" class="form-control" />
        <label>Empresa</label>
        <input type="text" id="empresa" class="form-control" />
        <label>Telefono</label>
        <input type="phone" id="telefono" class="form-control" />
        <label>Correo electr&oacute;nico</label>
        <input type="email" id="email" class="form-control" />
        <hr class="mt-2" />
        <button type="button" class="btn btn-primary my-4" @click="guardar">
          AGREGAR
        </button>
      </div>
    </div>
  </div>
</template>
<script></script>
<script>
import { reactive, ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){
      console.log('log');
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }  

    const guardar = () => {
      const data = new FormData();
      data.append("nombre", document.getElementById("cliente").value);
      data.append("empresa", document.getElementById("empresa").value);
      data.append("email", document.getElementById("email").value);
      data.append("phone", document.getElementById("telefono").value);
      axios
        .post("https://d7.osole.com.ar/balkun/formCliente", data)
        .then((response) => {
          // Manejar la respuesta de la API aquí
          console.log(response.data);
        })
        .catch((error) => {
          // Manejar el error aquí
          console.log(error);
        });
    };
    

    return {
      guardar,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
