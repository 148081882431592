<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h3>Elegir cliente</h3>
      <div class="py-3">
        <input
          type="text"
          list="clientes"
          id="cliente"
          @input="handleInput"
          v-model="selectedOption"
          class="form-control"
        />

        <datalist id="clientes">
          <option
            v-for="option in options"
            :key="option.id"
            :value="option.value"
          ></option>
        </datalist>
      </div>

      <div
        v-for="(pedidoHistorico, index) in pedidosHistoricos.pedido"
        :key="index"
        style="text-align: start"
      >
        <h3>Pedido N° {{ pedidoHistorico.numeroPedido }}</h3>
        <div>
          <div class="d-flex flex-row justify-content-between">
            <div>
              <div><b>Estado</b>: {{ pedidoHistorico.estado }}</div>
              <div><b>Ingreso</b>: {{ pedidoHistorico.ingreso }}</div>
            </div>
            <!-- MODAL CAMBIO DE ESTADO  -->
            <!-- Button trigger modal -->
            <div>
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src="../../public/img/icons/edit-boton.svg" />
              </button>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    <h3>Pedido N° {{ pedidoHistorico.numeroPedido }}</h3>
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    :id="'inputEstado_' + pedidoHistorico.numeroPedido"
                  >
                    <option selected>Seleccionar estado</option>
                    <option value="Recepcionado">Recepcionado</option>
                    <option value="En producci&oacute;n">
                      En producci&oacute;n
                    </option>
                    <option value="En expedici&oacute;n">
                      En expedici&oacute;n
                    </option>
                  </select>
                </div>
                
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="
                      editEstado(
                        pedidoHistorico.numeroPedido,
                        'inputEstado_' + pedidoHistorico.numeroPedido
                      )
                    "
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span v-if="pedidoHistorico.suelas">Suelas</span>
        <div
          v-for="(articulo, index) in pedidoHistorico.suelas"
          :key="index"
          class="p-2 my-5 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
            <b>{{ articulo.name }} {{ articulo.color }}</b>
            <div
              class="mx-2 d-flex flex-row flex-wrap suelas"
              :data-suela="articulo.name"
              :data-color="articulo.color"
            >
              <div
                class="suelas-talle"
                :data-talle="talle.talle"
                :data-cantidad="talle.cantidad"
                v-for="(talle, index) in articulo.talles"
                :key="index"
              >
                <div class="p-2">
                  <b>{{ talle.talle }}</b> - {{ talle.cantidad }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <button
              class="btn"
              @click="
                borrar(
                  pedidoHistorico.numeroPedido,
                  articulo.name,
                  articulo.color,
                  'suelas'
                )
              "
            >
              <img src="../../public/img/icons/delete-boton.svg" />
            </button>
          </div>
        </div>
        <hr />
        <span v-if="pedidoHistorico.telas.length > 0">Telas</span>
        <div
          v-for="(articulo, index) in pedidoHistorico.telas"
          :key="index"
          class="p-2 my-5 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
           <b>{{ articulo.name }}</b>
            <div
              class="mx-2 d-flex flex-row flex-wrap telas"
              :data-tela="articulo.name"
            >
              <div
                v-for="(color, index) in articulo.colores"
                :key="index"
                :data-telasColor="color.color"
                :data-telasCantidad="color.cantidad"
              >
                <div v-if="color.cantidad != 0" class="p-2 telas-color">
                  <b>{{ color.color }}</b> - {{ color.cantidad }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn"
              @click="
                borrar(pedidoHistorico.numeroPedido, articulo.name, '', 'telas')
              "
            >
              <img src="../../public/img/icons/delete-boton.svg" />
            </button>
          </div>
        </div>
      </div>
      <hr class="w-100" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import axios from "axios";

import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){      
      const navBar = document.getElementById("navBar");
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }

    const clientes = reactive([]);
    const selectedOption = reactive("");
    const options = reactive([]);
    //GET CLIENTE
    axios
      .get("https://d7.osole.com.ar/balkun/allclientes")
      .then((response) => {
        response.data.forEach(function (item) {
          const objArt = {
            id: item.id,
            value: item.empresa,
            label: item.empresa,
          };
          options.push(objArt);
        });
      })
      .catch((error) => {
        console.error(error);
      });

    //ESCUCHAR INPUT CLIENTE
    const handleInput = (event) => {
      const selectedOption = options.find(
        (options) => options.value === event.target.value
      );
      recargarPedidos(selectedOption);
    };

    const pedido = reactive({
      articulos: [],
    });

    const estado = reactive();

    const pedidoTela = reactive({
      articulos: [],
    });

    const pedidosHistoricos = reactive({
      pedido: reactive([]),
    });

    const recargarPedidos = (selectedOption) => {
      if (selectedOption) {
        let id = selectedOption.id;
        axios
          .get("https://d7.osole.com.ar/balkun/pendientes/" + id)
          .then((response) => {
            // Manejar la respuesta de la API aquí
            const pedidoHistorico = response.data;
            pedidosHistoricos.pedido = reactive([]);
            for (let index = 0; index < pedidoHistorico.length; index++) {
              const element = pedidoHistorico[index];
              const aux = {};
              const pedidoAux = JSON.parse(element.pedido);
              const fecha = new Date(element.updated_at);

              aux.suelas = JSON.parse(pedidoAux.suelas);
              aux.telas = JSON.parse(pedidoAux.telas);
              aux.numeroPedido = element.id;
              aux.estado = element.estado;
              aux.ingreso =
                fecha.getUTCDate() +
                "/" +
                fecha.getUTCMonth() +
                "/" +
                fecha.getUTCFullYear();
              pedidosHistoricos.pedido.push(aux);
            }
          })
          .catch((error) => {
            // Manejar el error aquí
            console.log(error);
          });
      }
    };
    const editEstado = (pedido, id) => {
      const data = new FormData();
      data.append("pedido", pedido);
      data.append("estado", document.getElementById(id).value);

      axios
        .post("https://d7.osole.com.ar/balkun/formEstado", data)
        .then((response) => {
          console.log(response.data);
          const selectedOption = options.find(
            (options) =>
              options.value === document.getElementById("cliente").value
          );
          recargarPedidos(selectedOption);
        })
        .catch((error) => {
          // Manejar el error aquí
          console.log(error);
        });
    };

    const borrar = (pedido, articulo, color, tipo) => {
      const data = new FormData();
      data.append("articulo", articulo);
      data.append("color", color);
      data.append("tipo", tipo);
      data.append("pedido", pedido);

      axios
        .post("https://d7.osole.com.ar/balkun/formBorrar", data)
        .then((response) => {
          console.log(response.data);
          recargarPedidos(selectedOption);
        })
        .catch((error) => {
          // Manejar el error aquí
          console.log(error);
        });
    };

    return {
      pedido,
      pedidoTela,
      estado,
      pedidosHistoricos,
      clientes,
      handleInput,
      selectedOption,
      options,
      editEstado,
      borrar,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
