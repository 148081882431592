<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">  
      <h3>Elegir cliente</h3>
      <div>
        <input
          type="text"
          list="clientes"
          id="cliente"
          @input="handleInput"
          v-model="selectedOption"
          class="form-control"
        />
  
        <datalist id="clientes">
          <option
            v-for="option in options"
            :key="option.id"
            :value="option.value"
          ></option>
        </datalist>
      </div>
      <hr class="py-2">
      <div class="py-3"  v-for="(pedidoHistorico, index) in pedidosHistoricos.pedido"
          :key="index" style="text-align: start">        
        <h3>Pedido N° {{ pedidoHistorico.numeroPedido }}</h3>
        <div><b>Estado</b>: {{ pedidoHistorico.estado }}</div>
        <span class="my-2">Suelas</span>
        <div
          v-for="(articulo, index) in pedidoHistorico.suelas"
          :key="index"
          class="p-2 my-1 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
           <b>{{ articulo.name }} {{ articulo.color }}</b>
            <div  class="mx-2 d-flex flex-row flex-wrap suelas" :data-suela="articulo.name" :data-color="articulo.color">
              <div class="suelas-talle" :data-talle="talle.talle" :data-cantidad="talle.cantidad" v-for="(talle, index) in articulo.talles" :key="index">
                <div class="p-2">
                  <b>{{ talle.talle }}</b> - {{ talle.cantidad }}
                </div>
              </div>
            </div>
          </div>          
        </div>
        <hr />
        <span class="my-2">Telas</span>
        <div
          v-for="(articulo, index) in pedidoHistorico.telas"
          :key="index"
          class="p-2 my-1 d-flex flex-row justify-content-between"
          style="border: 1px solid #ccc"
        >
          <div class="col-10">
            <b>{{ articulo.name }}</b>
            <div class="mx-2 d-flex flex-row flex-wrap telas" :data-tela="articulo.name">
              <div v-for="(color, index) in articulo.colores" :key="index" :data-telasColor="color.color" :data-telasCantidad="color.cantidad">
                <div v-if="color.cantidad != 0" class="p-2 telas-color" >
                  <b>{{ color.color }}</b> - {{ color.cantidad }}
                </div>
              </div>
            </div>
          </div>          
        </div>
        <hr class="w-100" />
      </div>
      
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import axios from 'axios';
import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){
      console.log('log');
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }

    const clientes = reactive([]);
    const selectedOption = ref("");
    const options = reactive([]);
    //GET CLIENTE
    axios
      .get("https://d7.osole.com.ar/balkun/allclientes")
      .then((response) => {        
        response.data.forEach(function (item) {          
          const objArt = {
            id: item.id,
            value: item.empresa,
            label: item.empresa,            
          };
          options.push(objArt);

          
        });        
      })
      .catch((error) => {
        console.error(error);
      });

    //ESCUCHAR INPUT CLIENTE
    const handleInput = (event) => {
      const selectedOption = options.find(
        (options) => options.value === event.target.value
        );
        if(selectedOption){
          let id = selectedOption.id          
          axios.get('https://d7.osole.com.ar/balkun/historial/'+id)
          .then(response => {
            // Manejar la respuesta de la API aquí              
            const pedidoHistorico = response.data;
            for (let index = 0; index < pedidoHistorico.length; index++) {            
              const element = pedidoHistorico[index];
              const aux = {}
              const pedidoAux = JSON.parse(element.pedido)
              aux.suelas = JSON.parse(pedidoAux.suelas)
              aux.telas = JSON.parse(pedidoAux.telas)
              aux.numeroPedido = element.id
              aux.estado = element.estado
              pedidosHistoricos.pedido.push(aux)
              console.log(element)
            }            
            //pedidosHistoricos.array.forEach(element => {
              
            //});
            //pedidosHistoricos.estado = historial.estado
            
  
          })
          .catch(error => {
            // Manejar el error aquí
            console.log(error);
          });
        }

    };
    
    const pedido = reactive({
      articulos: [],
    });

    const estado = reactive();

    const pedidoTela = reactive({
      articulos: [],
    });

    const pedidosHistoricos = reactive({
      pedido:[],      
    });




    return {
      pedido,
      pedidoTela, 
      estado,
      pedidosHistoricos,
      clientes,
      handleInput,
      selectedOption,
      options,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
