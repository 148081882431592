<template>
  <nav>
    <button class="navbar-toggler" @click="toggleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-brand w-100">
      <img src="../public/img/icons/balkun.svg" />
    </div>
    <div class="navbar-collapse" :class="{ show: isNavbarOpen }" id="navBar">
      <ul class="navbar-nav d-none">
        <li class="nav-item"><router-link to="/home">Inicio</router-link></li>
        <li class="nav-item">
          <router-link to="/pedidos/suelas">Pedidos</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pendientes">Pendientes</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/historial">Historial</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/carrito">Carrito</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/Clientes">clientes</router-link>
        </li>
        <li class="nav-item">
          <button class="btn" @click="logout">Cerrar session</button>
        </li>
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
export default {
  data() {
    const router = useRouter();
    const navBar = ref("");

    if (localStorage.getItem("token")) {
      console.log(localStorage);
    } else {
      router.push("/");
    }
    console.log(localStorage.getItem("token"));
    const logout = () => {      
      localStorage.clear();
      const navBar = document.getElementById('navBar');
      navBar.querySelector('ul').classList.add('d-none')
      router.push("/");
    };
    return {
      isNavbarOpen: false,
      logout,
      tokenExists: false,
    };
  },

  created() {    
    if (localStorage.getItem("token")) {
      this.tokenExists = true;
    }
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
body {
  background: #f4f4f4;
  font-family: "Roboto Condensed", sans-serif;
}
.box_container {
  background: #fff;
  padding: 25px;
}
a {
  border: unset !important;
  border-color: unset !important;
}
.nav.nav-tabs {
  border-bottom: unset;
}
.nav-link.active {
  color: #787878 !important;
}
.router-link-active.router-link-exact-active.nav-link.active {
  border-bottom: 2px solid #000 !important;
  color: #000 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  height: 55px;
}

.navbar-brand {
  font-weight: bold;
  margin: 0 1rem;
}

.navbar-toggler {
  background-color: transparent;
  border: none;
  padding: 0.25rem 0.5rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.8)' stroke-width='2' stroke-linecap='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
}

.navbar-collapse {
  display: flex;
  flex-direction: column;
  background-color: #444;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.navbar-collapse.show {
  max-height: 20rem;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  margin: 0.5rem 1rem;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
}
</style>
