<template>
  <form @submit.prevent="submit">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <div class="p-3 box_container py-5">
        <label class="py-1">Nombre de usuario</label>
        <input type="text" id="username" class="form-control mb-4" />

        <label class="py-1">Contraseña</label>
        <input type="password" id="password" class="form-control" />

        <button
          type="button"
          class="btn btn-primary my-5"
          @click="enviarFormulario"
        >
          Iniciar sesión
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router'
import { useStore } from "vuex";


export default {
  setup() {
    const router = useRouter()
    const store = useStore();    

    //FORMULARIO
    const enviarFormulario = () => {
      
      const data = new FormData();
      data.append("username", document.getElementById("username").value);
      data.append("password", document.getElementById("password").value);
      data.append("api", true);
      // let config = {
      //   headers: {"Content-Type":"application/json",},
      // };
      axios
        .post("https://d7.osole.com.ar/balkun/formLogin", data)
        .then((response) => {
          // Manejar la respuesta de la API aquí
          //const credentials = { empresa: 'empresa', password: 'empresa' };
          console.log("login ok");
          if(response.data.usuario != 'datos incorrectos'){
            const  token  = 'bBaAlLkKuUmMpPwWaA';
            localStorage.setItem('token',token)
            localStorage.setItem('usuario',response.data)
            store.commit('setToken', token)          
            //location.reload()
            router.push('/home')
          }
          
        })
        .catch((error) => {
          // Manejar el error aquí
          console.log(error);
        });
    };

    return {
      enviarFormulario,
    };
  },
};
</script>
<style>
.box {
  width: 328px;
  height: 184px;
}
</style>
