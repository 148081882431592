<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="box_container">
      <h1>PEDIDOS TELAS</h1>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <router-link
            to="/pedidos/suelas"
            class="nav-link active"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Suelas
          </router-link>
        </li>
        <li class="nav-item" role="presentation">
          <router-link
            to="/pedidos/telas"
            class="nav-link active"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Telas
          </router-link>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          <div class="py-5">
            <!-- ARTICULO -->
            <div class="my-2" style="text-align: start">
              <b>Eleg&iacute;r categoria producto</b>
            </div>
            <input
              type="text"
              list="telas"
              id="tela"
              @input="handleInput"
              v-model="selectedOption"
              data-talle=""
              class="form-control"
            />
            <datalist id="telas">
              <option
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                :data-categoria="option.tipo_articulo_id"
              ></option>
            </datalist>

            <!-- Articulos -->
            <div v-if="selectedOption">
              <div class="my-2" style="text-align: start">
                <b>Articulo</b>
              </div>
              <input type="text" list="articulos" class="form-control" id="articulo" />
              <datalist id="articulos">
                <option
                  v-for="(articulo, index) in articulos"
                  :key="index"
                  :value="articulo"
                  :data-colores="articulo.colores"
                ></option>
              </datalist>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-primary" @click="pedido">
        AGREGAR
      </button>
    </div>
  </div>
</template>
<script></script>
<script>
import { reactive, ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    
    if(localStorage.getItem('token')){      
      const navBar = document.getElementById('navBar');
      navBar.classList.remove("show");
    }else{
      router.push('/')
    }


    const selectedOption = ref("");
    let colores = [];
    let talles = [];
    const options = reactive([]);

    axios
      .get(" https://d7.osole.com.ar/balkun/alltelacat")
      .then((response) => {
        response.data.forEach(function (item) {
          const objArt = {
            id: item.id,
            value: item.name,
            label: item.name,
            articulos: item.articulos,
          };
          options.push(objArt);
        });
      })
      .catch((error) => {
        console.error(error);
      });

    const handleInput = (event) => {
      const selectedOption = options.find(
        (options) => options.value === event.target.value
      );
      if (selectedOption) {
        let telas = document.getElementById("tela");
        telas.dataset.articulos = JSON.stringify(selectedOption.articulos);

        let listArticulos = document.getElementById("articulos");
        let arrArticulos = selectedOption.articulos;

        listArticulos.innerHTML = "";
        arrArticulos.forEach(function (item) {
          var option = document.createElement("option");
          option.value = item["name"];
          option.dataset.categoria = item["tipo_articulo_id"];
          option.dataset.colores = JSON.stringify([
            "Marrón",
            "Beish",
            "Blanco",
            "Negro",
          ]);
          listArticulos.appendChild(option);
        });
        var input = document.getElementById("articulo");
        var datalist = document.getElementById("articulos");
        input.addEventListener("input", function () {
          let selectedArticulos = datalist.querySelector(
            'option[value="' + input.value + '"]'
          );
          let coloresArticulo = selectedArticulos.getAttribute("data-colores");
          input.setAttribute("data-colores", coloresArticulo);
        });
      }
    };

    const pedido = () => {
      let telas = document.getElementById("tela");
      let articulo = document.getElementById("articulo");
      let pedido;
      const objPedido = {
        articulo: telas.value,
        articulo: articulo.value,
        colores: articulo.dataset.colores,
      };
      
      localStorage.setItem("pedidoTela", JSON.stringify(objPedido));
      
      window.location.href = window.location.protocol + "//" + window.location.host + "/pedidos_telas/";
    };

    return {
      selectedOption,
      handleInput,
      options,
      colores,
      talles,
      pedido,
    };
  },
};
</script>

<style>
.box {
  width: 328px;
  height: 184px;
}
.box_container {
  max-width: 328px;
}
</style>
